/**
 * This function builds the url for the cheapest price with the appropiate filters set.
 * @param carId id of car to be retrieved
 */
export const buildTopPromotedCarUrl = () => {
    const config = useRuntimeConfig();
    const currentCountry = useCurrentCountry();

    return `${config.public.icsBaseUrl}/service/public/car/${currentCountry.value}/promo/top-promoted/standard/`;
};
